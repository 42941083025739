import * as React from "react"
import {useContext} from "react"
import PageWithoutHero from "../../../components/PageWithoutHero"
import {ErrorMessage, Field, Form, Formik} from "formik"
import styled from "styled-components"
import {db} from "../../../utils/firebase"
import {doc, setDoc} from "firebase/firestore"
import {navigate} from "gatsby-link"
import {AuthContext} from "../../../utils/context"

const StyledFormGroup = styled.div`
  margin-bottom: 20px;
`

export const priceModifiers = {
    "overall sqft": 0,
    "bathroom floor sqft": 0,
    "laundry/mudroom floor sqft": 0,
    "exterior windows and door": 0,
    "interior doors": 0,
    "case and base lin ft": 0,
    "vaulted sqft": 0,
    "kitchen lin ft": 0,
    "kitchen sink": 0,
}

const Create = (props) => {
    const {isAdmin} = useContext(AuthContext)

    return (
        <PageWithoutHero>
            <h2>Create a new build</h2>
            {!isAdmin &&
                <div>
                    <strong>You don't have access to create builds</strong>
                </div>
            }
            {isAdmin &&
                <Formik
                    initialValues={{
                        code: "",
                        last_name: "",
                        email: "",
                        currency: "CAD",
                        currency_conversion_factor: 1,
                        first_phase_cutoff_date: new Date(),
                        mobile_phone: "",
                        ...priceModifiers

                    }}
                    validate={values => {
                        const errors = {}
                        if (!values.email) {
                            errors.email = "Required"
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = "Invalid email address"
                        }
                        return errors
                    }}
                    onSubmit={async (values, {setSubmitting}) => {
                        const response = await setDoc(doc(db, "builds", values.code.toUpperCase()), {
                            ...values,
                            code: values.code.toUpperCase(),
                            first_phase_cutoff_date: new Date(values.first_phase_cutoff_date),
                            selections: [],
                            pre_selections: [],
                            credits: 0,
                            total: 0
                        })
                        navigate(`/admin/build-selector/${values.code}/pre-selections/`)
                        setSubmitting(false)
                    }}
                >
                    {({isSubmitting}) => (
                        <Form>
                            <StyledFormGroup>
                                <label htmlFor="code">Code</label><br/>
                                <Field type="text" name="code"/>
                                <ErrorMessage name="code" component="div"/>
                            </StyledFormGroup>

                            <StyledFormGroup>
                                <label htmlFor="last_name">Last Name</label><br/>
                                <Field type="text" name="last_name"/>
                                <ErrorMessage name="last_name" component="div"/>
                            </StyledFormGroup>

                            <StyledFormGroup>
                                <label htmlFor="mobile_phone">Mobile Phone</label><br/>
                                <Field type="tel" name="mobile_phone"/>
                                <ErrorMessage name="mobile_phone" component="div"/>
                            </StyledFormGroup>

                            <StyledFormGroup>
                                <label htmlFor="email">Email</label><br/>
                                <Field type="email" name="email"/>
                                <ErrorMessage name="email" component="div"/>
                            </StyledFormGroup>

                            <StyledFormGroup>
                                <label htmlFor="currency">Currency</label><br/>
                                <Field as="select" name="currency">
                                    <option value={'CAD'}>CAD</option>
                                    <option value={'USD'}>USD</option>
                                </Field>
                                <ErrorMessage name="currency" component="div"/>
                            </StyledFormGroup>

                            <StyledFormGroup>
                                <label htmlFor="currency_conversion_factor">Currency Conversion Factor</label><br/>
                                <Field type="number" name="currency_conversion_factor"/>
                                <ErrorMessage name="currency_conversion_factor" component="div"/>
                            </StyledFormGroup>

                            <StyledFormGroup>
                                <label htmlFor="first_phase_cutoff_date">Phase 1 Cutoff Date</label><br/>
                                <Field type="date" name="first_phase_cutoff_date"/>
                                <ErrorMessage name="first_phase_cutoff_date" component="div"/>
                            </StyledFormGroup>
                            <h4>Build information</h4>
                            {Object.keys(priceModifiers).map((key, index) => {
                                return (
                                    <StyledFormGroup>
                                        <label htmlFor={key}>{key}</label><br/>
                                        <Field type="number" name={key}/>
                                        <ErrorMessage name={key} component="div"/>
                                    </StyledFormGroup>
                                )
                            })}
                            <input type="submit" disabled={isSubmitting} value={'Create'} />
                        </Form>
                    )}
                </Formik>
            }
        </PageWithoutHero>
    )
}

export default Create
